<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="账单月份" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="month"
            placeholder="选择月"
            format="yyyy 年 MM 月"
            value-format="yyyy-MM"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="供应商" prop="supplier_id">
          <el-select v-model="table.params.supplier_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getSupplier" @change="onSearch">
            <el-option v-for="item in supplierOpt" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input
            v-model.trim="table.params.contact"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd" style="display:flex;align-items: center;">
        <el-button :size="subUnitSize" type="primary" plain @click="exportDataAll('/admin/bill/export')" icon="el-icon-download" >导出</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      :showIndex="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:action="{row}">
        <el-button type="text" @click="showDetail(row)">查看</el-button>
        <el-button type="text" @click="exportData(row)">导出明细</el-button>
      </template>
    </VTable>
    <div style="position: absolute;bottom: 30px;left: 35px;">
      <span style="color:#f56c6c;font-size:14px;font-weight:bold;">采购金额汇总: {{table.total_delivery_money}}元</span>
    </div>
    <!-- 明细详情 -->
    <detail ref="detail" :sleDate="table.params" @refresh="getTable"></detail>

  </div>
</template>

<script>
import Detail from './components/Detail.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'

export default {
  name: 'SupplierIndex',
  components: {
    Detail,
    VTable,
  },
  mixins: [mixinTable],
  computed:{
    ...mapGetters(['areaArr'])
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "supplier_name", label: "供应商名称", hidden: false },
        { name: "contact", label: "联系人", hidden: false},
        // { name: "contact_phone", label: "联系电话", hidden: false},
        { name: "total_delivery_num", label: "采购单(笔)", hidden: false},
        { name: "total_delivery_money", label: "采购金额(元)",  hidden: false},
        { name: "change_time", label: "最新收货时间",  hidden: false},
        { name: "action", label: "操作",width: "120", fixed:"right", hidden: false }
      ],
      loading: false,
      table: {
        loading: false,
        params: {
          date: '', // 当前月
          supplier_id: '',
          contact: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        total_delivery_money: 0, //采购金额汇总
      },
      supplierOpt: [], // 供应商
    }
  },
  created() {
    this.table.params.date = this.$moment(new Date()).format("YYYY-MM"); // 默认当前月份
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      this.table.loading = true;
      this.$http.get('/admin/bill/list', {params:_params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total_delivery_money = res.data.total_delivery_money; // 采购金额汇总
        }
      })
    },
    // 供应商
    getSupplier() {
      this.$http.get('/admin/supplier/list',{params:{page:1, count:10000}}).then(res => {
        if(res.code == 1) {
          this.supplierOpt = res.data.list;
        }
      })
    },
    // 下单明细
    showDetail(row) {
      let dom = this.$refs.detail;
      dom.toggle(true)
      let obj = {
        ...row,
        month: this.table.params.date, // 下单月份
      }
      dom.getDetail(obj)
      dom = null
    },
    // 导出供应商账单列表
    exportDataAll(url) {
      let obj = { ...this.table.params }
      // 没有勾选数据默认是全部导出，勾选了数据就是批量导出
      if(this.multipleSelection.length > 0) {
        obj.ids = this.multipleSelection.map(v => { return v.bind_id}).join("|")
        let data = {
          url: url,
          obj: obj,
        }
      this.exportFun(data)
      } else {
        this.$confirm('确定导出列表全部数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          let data = {
            url: url,
            obj: obj,
          }
          this.exportFun(data)
        }).catch(() => {})
      }
    },
    exportFun(data) {
      let obj = data.obj;
      let url = data.url;
      let str = ""
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
    // 供应商账单导出明细细
    exportData(row) {
      let url = '/admin/bill/exportDetail'
      let obj = {
        month: this.table.params.date,
        supplier_id: row.bind_id
      }
      let str = ""
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    }
  }
}
</script>
